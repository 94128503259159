.Gallery {
    width: 100%;
    height: 100%;

    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: center;
}

.Gallery img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    border-radius: 10px;
}

.Gallery .pages-demo,
.Gallery .PXH2910,
.Gallery .PXH2910 .react_lightgallery_item {
    width: 300px;
    height: 170px;

    cursor: pointer;
}

.Gallery .list-pages-demo .pages-demo {
    background-position: center;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 0;

    border-radius: 10px;
}

.Gallery .PXH2910 {
    position: relative;
}

.Gallery .PXH2910 .react_lightgallery_item {
    position: absolute;
}

.Gallery .PXH2910 > .react_lightgallery_item:first-child {
    z-index: 1;
    opacity: 0;
}
