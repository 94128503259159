.ImagesGame {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: center;
}

.ImagesGame img {
    max-width: 100%;
    border-radius: 15px;
}

.ImagesGame .sub-box {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 170px;
}

.ImagesGame .sub-box .overlayer-v1 {
    position: absolute;
    background-color: rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
}

.ImagesGame .sub-box .overlayer-v1 .overlayer-v2 {
    width: 100%;
    height: 100%;
    display: none;
    text-align: center;

    position: absolute;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;

    color: rgba(255, 255, 255, 1);
}

.ImagesGame .sub-box .overlayer-v1 .overlayer-v2 .btn-playgame {
    font-size: 40px;
    margin: 10px;
}

.ImagesGame .sub-box .overlayer-v1 .overlayer-v2 .tutorial span {
    font-size: 14px;
    display: block;
}

.ImagesGame .sub-box .overlayer-v1:hover .overlayer-v2 {
    display: block;
}

.ImagesGame .box .title {
    font-size: 20px;
    font-weight: 700;
}

.ImagesGame .box .platform {
    font-size: 14px;
    font-weight: 500;
}

/* Animations button load more */
.btn-loading {
    margin: 100px auto;
    width: 180px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.btn-loading::before {
    content: attr(content);
    position: absolute;
    width: 175px;
    height: 45px;
    z-index: 1;
    background-color: rgba(238, 244, 247, 1);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.btn-loading::after {
    content: "";
    width: 400px;
    height: 400px;
    position: absolute;
    border-radius: 50px;
    background: conic-gradient(#3a7cec 0%12.5%,
            #2ca24c 12.5%25%,
            #f1b500 25%37.5%,
            #e33e2b 37.5%50%,
            #3a7cec 50%62.5%,
            #2ca24c 62.5%75%,
            #f1b500 75%87.5%,
            #e33e2b 87.5%100%);
    animation: border-animation 5s linear infinite;
    -webkit-animation: border-animation 5s linear infinite;
}

@keyframes border-animation {
    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}