.Code {

    box-sizing: border-box;

    width: 100%;
    height: 100%;

    border: 0px solid black;

    padding: 15px;

    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: center;

    grid-gap: 50px;
}

.Code .item {
    width: 100px;
    height: 100px;
    /* border: 2px dashed rgba(255, 128, 40, 1); */
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    font-size: 0.75rem;

    padding: 5px;

    cursor: pointer;

    color: rgba(255, 255, 255, 1);
}

.Code .item a {
    text-decoration: none;
    color: rgb(255 255 255);
    display: block;
    width: 100%;
    overflow-wrap: break-word;
}